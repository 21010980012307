import { render, staticRenderFns } from "./RolesTable.vue?vue&type=template&id=c7b2288a&scoped=true&"
import script from "./RolesTable.vue?vue&type=script&lang=js&"
export * from "./RolesTable.vue?vue&type=script&lang=js&"
import style1 from "./RolesTable.vue?vue&type=style&index=1&id=c7b2288a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7b2288a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBtn,VCard,VCardText,VDataTable,VDialog,VIcon})
