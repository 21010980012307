<template>
  <v-card>
    <v-card-text>
      <v-data-table
        locale="fr-FR"
        :page="options.page"
        :pageCount="numberOfPages"
        :headers="headers"
        :items="items"
        :sort-by="'updated'"
        :sort-desc="true"
        :options.sync="options"
        :loading="loading"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options': [10, 25, 50, 100],
        }"
        :items-per-page="options.itemsPerPage"
      >
        <template v-slot:item.actions="{ item }">
          <a target="_blank">
            <v-btn
              :to="`/users/roles/edit/${item.id}`"
              class="mx-2"
              elevation="0"
              fab
              small
            >
              <v-icon color="primary">
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
            <v-btn
              class="mx-2"
              elevation="0"
              fab
              small
              @click.native="
                dialogModel.dialog = !dialogModel.dialog;
                dialogModel.id = item.id;
                dialogModel.entryName = item.name;
              "
            >
              <v-icon color="secondary">
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </a>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-model="dialogModel.dialog" width="70vw">
      <del-confirmation
        :dialogOpen="dialogModel.dialog"
        :id="dialogModel.id"
        :name="dialogModel.entryName"
        @openDialog="openDialog"
      ></del-confirmation>
    </v-dialog>
  </v-card>
</template>
<style scoped></style>
<script>
import axios from "@axios";
import DelConfirmation from "@/components/admin/users/roles/modals/RoleDelConfirmation.vue";
import { mdiPencil, mdiTrashCan } from "@mdi/js";
import debounce from "lodash/debounce";

export default {
  name: "RolesTable",
  components: { DelConfirmation },
  created() {
    this.debouncedUpdateData();
  },
  data() {
    return {
      search: "",
      numberOfPages: 0,
      items: [],
      loading: false,
      total: 0,
      options: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
      },
      dialogModel: {
        dialog: false,
        id: -1,
        entryName: "",
      },
      headers: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Nom",
          align: "start",
          value: "name",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Permissions",
          value: "permissions",
        },
        {
          value: "actions",
        },
      ],
      icons: {
        mdiPencil,
        mdiTrashCan,
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.debouncedUpdateData();
      },
    },
    search() {
      this.options.page = 1;
      this.debouncedUpdateData();
    },
  },
  methods: {
    debouncedUpdateData: debounce(async function () {
      await this.updateData();
    }, 500),
    async updateData() {
      this.loading = true;
      const { data } = await axios.get("/roles/");
      this.loading = false;
      this.items = data.items;
      this.total = data.total;
      this.numberOfPages = data.total / this.options.itemsPerPage;
    },
    truncate(input) {
      return input.length > 5 ? `${input.substring(0, 30)}...` : input;
    },
    openDialog(value) {
      this.dialogModel.dialog = value;
      if (!value) {
        this.debouncedUpdateData();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-with-ga-id {
  max-width: 300px;
}
.above-table {
  width: 100%;
}
.search-by-site-id {
  width: 100px;
}
</style>
